<template>
    <v-row>
        <v-col cols="12" sm="6">
            <v-select label="Level Type" :items="level_types" v-model="LevelType"></v-select>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field label="Search" v-model="search"></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-data-table dense :search="search" :headers="the_headers" :items="filtered_history" item-key="id" class="elevation-0" :loading="loading" show-expand :expanded.sync="expanded">
                <template v-slot:item.ID="{ item }">
                    <v-btn small icon @click="emitAgentLevel(item)">
                        <v-icon small>fas fa-pencil-alt</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.LockedInd="{ item }">
                    <span>
                        <v-icon small v-if="item.LockedInd">fas fa-lock</v-icon>
                    </span>
                </template>
                <template v-slot:item.LevelName="{ item }">
                    <span v-if="item.OverrideLevelID == 0">{{item.LevelName}}</span>
                    <span v-else><strike>{{item.LevelName}}</strike>&nbsp;{{item.OverrideLevelName}}</span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="text-left">
                        <pre class="ml-10 mt-5 mb-5">{{item.Notes}}</pre>
                    </td>
                </template>
                <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
                    <span>
                        <v-icon v-if="item.Notes" @click="expand(!isExpanded)" small>fas fa-sticky-note</v-icon>
                    </span>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ['agent'],
    data() {
        return {
            LevelType: "Leadership",
            loading: false,
            history: [],
            historyLevels: [],
            search: null,
            expanded: [],
            selected: null,
            the_headers: [
                { text: '', value: "data-table-expand" },
                {
                    text: 'Level / Override',
                    value: 'LevelName',
                    align: 'start',
                    sortable: true,
                }, {
                    text: 'Start',
                    value: 'StartDate',
                    align: 'start',
                    sortable: true,
                    class: "no-wrap"
                }, {
                    text: 'End',
                    value: 'EndDate',
                    align: 'start',
                    sortable: true,
                    class: "no-wrap"
                }, {
                    text: 'Locked',
                    value: 'LockedInd',
                    sortable: true,
                    align: 'center',
                }, {
                    text: 'Change By',
                    value: 'LastChangeBy',
                    sortable: true,
                    align: 'start',
                }, {
                    text: 'Edit',
                    value: 'ID',
                    sortable: false,
                    align: 'center',
                }
            ],
        }
    },
    mounted: function() {
        this.reset();
    },
    methods: {
        emitAgentLevel(level) {
            this.$emit('load', { ...level })
        },
        reset() {
            var g = this;
            this.loading = true
            QuilityAPI.getAgentManagement_AgentLevels(this.agent.AgentCode).then(function(data) {
                g.$set(g, 'history', data)
                g.loading = false;
            }).catch(function(err) {
                if (err && err.msg) {
                    g.showError(err.msg);
                }
                g.loading = false;
            })
        },
        setLevelType: function($type) {
            this.LevelType = $type;
        }
    },
    computed: {
        'filtered_history': function() {
            var g = this;
            g.historyLevels = g.history.filter(item => { return item.LevelType == g.LevelType });
            g.$store.state.history_levels = g.historyLevels
            // console.info('history: ', g.history)

            return g.historyLevels
        },
        'level_types': function() {
            return [
                'Leadership',
                'Producer',
                'Contract'
            ]
        }
    },
    watch: {
        'agent.AgentCode': function() {
            var g = this
            this.$nextTick(function() {
                g.reset();
            })
        }
    }
}

</script>
<style scoped>
.no-wrap {
    white-space: nowrap;
}

</style>
