<template>
    <div>
        <h2>Notes</h2>
        <v-btn outlined color="primary" @click="newNote">Add Note <v-icon right>fas fa-plus</v-icon>
        </v-btn>
        <v-row>
            <v-col cols="6" sm="4">
                <v-text-field v-model="search" label="Search" clearable></v-text-field>
            </v-col>
        </v-row>
        <v-data-table :mobile-breakpoint="2" :search="search" :headers="the_headers" :items="noteList" item-key="ID" class="elevation-0 leaderboard-datatable" :loading="loading" @click:row="previewNote" :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page.sync="rows">
            <template v-slot:item.ID="{ item }">
                <span class="nowrap">
                    <v-btn small fab icon @click.stop="previewNote(item)">
                        <v-icon color="q_agency_1">fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn color="q_resources_1" small fab icon @click.stop="editNote(item)">
                        <v-icon>fas fa-edit</v-icon>
                    </v-btn>
                </span>
            </template>
            <template v-slot:item.NoteTitle="{ item }">
                <span>
                    <strong>{{item.NoteTitle}}</strong>
                </span>
            </template>
            <template v-slot:item.NoteText="{ item }">
                <span>
                    {{briefNote(item.NoteText)}}
                </span>
            </template>
            <template v-slot:item.Link="{ item }">
                <span>
                    <a :href="item.Link" target="_tab">{{item.Link}}</a>
                </span>
            </template>
            <template v-slot:item.Delete="{ item }">
                <span v-if="item.DocType != 'delete'">
                    <v-btn color="red" v-if="hasPermission('manage:Agent')" small fab icon @click.stop="deleteNote(item)">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>
                </span>
                <span v-else>
                    <v-progress-circular indeterminate></v-progress-circular>
                </span>
            </template>
        </v-data-table>
        <v-dialog v-model="showNotePreview" width="50%">
            <v-card style="position:relative">
                <v-card-title>{{ currentNote.NoteTitle }}</v-card-title>
                <v-card-text>
                    <p v-html="formatNoteText(currentNote.NoteText)"></p>
                    <p class="text-right">{{ currentNote.LastChangeBy }}<br><em>{{ formatDateCalendar(currentNote.LastChangeDate) }}</em></p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showEditNote" width="50%">
            <v-card>
                <v-card-title>
                    <span v-if="currentNote.ID > 0">Edit Note</span>
                    <span v-else>New Note</span>
                </v-card-title>
                <v-card-text v-if="showEditNote">
                    <v-text-field v-model="currentNote.NoteTitle" label="Title"></v-text-field>
                    <v-textarea outlined v-model="currentNote.NoteText" label="Text"></v-textarea>
                    <v-text-field persistent-hint hint="https://" v-model="currentNote.Link" label="Link"></v-text-field>
                    <v-checkbox v-model="currentNote.StickyInd" label="Make Sticky"></v-checkbox>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" large @click="saveChanges">Save</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    props: ['agent'],
    data() {
        return {
            loading: false,
            noteList: [],
            showNotePreview: false,
            search: null,
            rows: 25,
            showEditNote: false,
            currentNote: {}
        }
    },
    mounted: function() {
        this.updateNoteList();
    },
    computed: {
        the_headers: function() {
            return [{
                text: '',
                value: 'ID',
                width: "200"
            }, {
                text: 'Title',
                value: 'NoteTitle',
            }, {
                text: 'Text',
                value: 'NoteText',
            }, {
                text: 'Link',
                value: 'Link',
            },{
                text: 'Date',
                value: 'LastChangeDate'
            },{
                text: 'Last Change By',
                value: 'LastChangeBy',
            }, {
                text: 'Delete',
                value: 'Delete',
                width: "100"
            }]
        },
    },
    methods: {
        briefNote: function(desc) {
            if (desc == null) {
                return '';
            }
            var append = "";
            if (desc.length > 25) {
                append = "...";
            }
            return desc.substring(0, 75) + append;
        },
        updateNoteList() {
            var g = this
            this.loading = true;
            QuilityAPI.getAgentNoteList(this.agent.AgentCode, this.role).then(function(json) {
                g.$set(g, 'noteList', json.data)
                g.loading = false;
            }).catch(function(err) {
                g.showError(err.msg)
                g.loading = false;
            })
        },
        editNote: function(note) {
            this.$set(this, 'currentNote', note);
            this.showEditNote = true;
        },

        newNote: function() {
            var note = {
                ID: null,
                NoteTitle: '',
                NoteText: '',
                StickyInd: false,
                Link: '',
            }
            this.$set(this, 'currentNote', note);
            this.showEditNote = true;
        },

        deleteNote: function(note) {
            var g = this
            this.loading = true
            QuilityAPI.deleteAgentNote(note.ID)
                .then(
                    function(json) {
                        if (json.success === true) {
                            var ix = g.indexByKey(note.ID, g.noteList, "ID");
                            g.noteList.splice(ix, 1);
                            g.loading = false
                        } else {
                            g.showError("Could not delete this note.")
                            g.loading = false
                        }
                    })
                .catch(
                    function(err) {
                        g.showError(err)
                        g.loading = false
                    })
        },
        saveChanges: function() {
            if (this.currentNote.ID > 0) {
                this.saveNote()
            } else {
                this.createNote()
            }
        },
        saveNote: function() {
            var g = this
            QuilityAPI.updateAgentNote(this.currentNote)
                .then(
                    function(json) {
                        if (json.success === true) {
                            g.showEditNote = false;
                            var ix = g.indexByKey(g.currentNote.ID, g.noteList, "ID");
                            g.$set(g.noteList, ix, json.note);
                        } else {
                            g.showError("Could not save changes.")
                            g.showEditNote = false;
                        }
                    })
                .catch(
                    function(err) {
                        g.showError(err)
                        g.showEditNote = false;
                    })
        },
        createNote: function() {
            var g = this
            QuilityAPI.createAgentNote(this.agent, this.currentNote)
                .then(
                    function(json) {
                        if (json.success === true) {
                            g.showEditNote = false;
                            g.updateNoteList();
                        } else {
                            g.showError("Could not create note.")
                        }
                    })
                .catch(
                    function(err) {
                        console.log(err)
                        g.showError(err)
                    })
        },
        previewNote: function(note) {
            this.$set(this, 'currentNote', note);
            this.showNotePreview = true;
        },
        formatNoteText(text) {
            if (text == null) {
                return '';
            }
            return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
    },
    watch: {
        'agent.AgentCode': function(newV) {
            var g = this;
            this.$nextTick(function() {
                g.updateNoteList()
            })
        },
    },
    components: {

    }
}

</script>
