<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="bulk_jobs_datatable">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}
                            <v-btn dark icon @click="refreshData">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <!--<p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                        </p>//-->
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" show-expand :expanded.sync="expanded">
                <template v-slot:item.status="{ item }">
                    <span class="nowrap" v-if="item.status == 'new'">
                        <div>
                            <v-icon color="#0083EB" medium class="pr-3">fas fa-file-alt</v-icon>
                            {{ item.status }}
                        </div>
                    </span>
                    <span class="nowrap" v-if="item.status == 'open'">
                        <div>
                            <v-icon color="#6ECF93" medium class="pr-3">fas fa-file-alt</v-icon>
                            {{ item.status }}
                        </div>
                    </span>
                    <span class="nowrap" v-if="item.status == 'pending'">
                        <div>
                            <v-icon color="#FF9F1C" medium class="pr-3">fas fa-file-alt</v-icon>
                            {{ item.status }}
                        </div>
                    </span>
                    <span class="nowrap" v-if="item.status == 'on-hold'">
                        <div>
                            <v-icon color="amber accent-4" medium class="pr-3">fas fa-exclamation-triangle</v-icon>
                            {{ item.status }}
                        </div>
                    </span>
                    <span class="nowrap" v-if="item.status == 'solved'">
                        <div>
                            <v-icon color="#6ECF93" medium class="pr-3">fas fa-check</v-icon>
                            {{ item.status }}
                        </div>
                    </span>
                    <span class="nowrap" v-if="item.status == 'closed'">
                        <div>
                            <v-icon color="#99CDF7" medium class="pr-3">fas fa-check-circle</v-icon>
                            {{ item.status }}
                        </div>
                    </span>
                </template>
                <!--adds view ticket icon -->
                <template v-slot:item.created_at="{ item }">
                    <span class="nowrap">
                        {{ formatDateCalendar(item.created_at) }}
                    </span>
                </template>
                <template v-slot:item.updated_at="{ item }">
                    <span class="nowrap">
                        {{ formatDateCalendar(item.updated_at) }}
                    </span>
                </template>
                <template v-slot:item.id="{ item }">
                    <span class="nowrap" v-if="!hasRole(['AgencyOwner', 'SalesRep'])">
                        <v-btn text small class="q_new_b_1--text  body-2" @click="viewTicket(item)">
                            <v-icon small>fas fa-external-link-alt</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <q-view-agent-ticket :agentcode="agent.AgentCode" :id="item.id"></q-view-agent-ticket>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "./QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QViewAgentTicket from '@/views/Support/QViewAgentTicket.vue';

export default {
    name: "QAgentSupportTicketsDataTable",
    props: ['agent'],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            options: {
                'sortBy': [],
                'sortDesc': [true]
            },
            expanded: [],
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Status',
                    value: 'status',
                    class: "nowrap",
                    sortable: false,
                }, {
                    text: 'Subject',
                    value: 'subject',
                    sortable: false,
                }, {
                    text: 'Created',
                    value: 'created_at',
                    sortable: false,
                }, {
                    text: 'Last Update',
                    value: 'updated_at',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'id',
                    class: "nowrap",
                    sortable: false,
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getAgentSupportTickets(this.agent, this.filters, { ...opt }, this.role).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
        viewTicket: function(item) {
            window.open('https://symmetry.zendesk.com/agent/tickets/' + item.id, '_blank');
        },
    },
    watch: {

    },
    components: {
        QViewAgentTicket
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
